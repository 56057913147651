<template>
  <v-dialog v-model="dialog" persistent max-width="874px">
    <v-card class="select-room-type-modal">
      <v-card-title>
        <span class="select-room-type-modal__title"
          >Choississez la pièce a rénover</span
        >
        <v-btn fab dark color="info" text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="select-room-type-modal__body">
          <v-row>
            <v-col
              cols="3"
              v-for="(room, roomIndex) in rooms"
              :key="`room-${roomIndex}`"
              @click="selectRoomType(room)"
            >
              <div class="room-item">
                <div
                  class="room-item__image"
                  :style="styleItem(room.image)"
                ></div>
                <div class="room-item__text">{{ room.name }}</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { RoomService } from "@/services/room.service.js";
import { pathImage } from "@/utils/index.js";

export default {
  name: "SelectRoomTypeModal",
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      rooms: [],
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
  },
  computed: {
    styleItem() {
      return (path) => {
        let pathUrl = pathImage(path);
        return pathUrl ? { backgroundImage: `url(${pathUrl})` } : null;
      };
    },
  },
  created() {
    this.fetchRooms();
  },
  methods: {
    fetchRooms() {
      RoomService.getRooms().then(({ data }) => {
        this.rooms = data;
      });
    },
    selectRoomType({ id, name }) {
      this.$emit("selectRoomType", { id, name });
    },
  },
};
</script>

<style src="./SelectRoomTypeModal.scss" lang="scss" scoped></style>